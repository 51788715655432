import './App.css';
import { DriversPage } from "./pages/drivers/DriversPage";
import { Navigate, Route, Routes } from 'react-router-dom';
import { GuardRoute } from './components/common/GuardRoute';
import { observer } from 'mobx-react';
import { DriverDetailsPage } from './pages/drivers/DriverDetailsPage';
import LoginPage from './pages/auth/LoginPage';
import SetPasswordPage from './pages/auth/SetPasswordPage';
import RegisterPage from './pages/auth/RegisterPage';
import PasswordRecoveryPage from './pages/auth/PasswordRecoveryPage';
import { NewMedicalCheckupsPage } from './pages/medicalCheckups/NewMedicalCheckupsPage';
import {ROUTES} from './constants/routes'
import { MedicalCheckupsHistoryPage } from './pages/medicalCheckups/MedicalCheckupsHistoryPage';
import { MedicalCheckupDetailsPage } from './pages/medicalCheckups/MedicalCheckupDetailsPage';
import { MedicalCheckupsJournalPage } from './pages/medicalCheckups/MedicalCheckupsJournalPage';
import NotFoundPage from './pages/auth/NotFoundPage';
import { featureTogglesService } from './services/featureTogglesService';
import { TerminalsPage } from './pages/terminals/TerminalsPage';
import { TerminalDetailsPage } from './pages/terminals/TerminalDetailsPage';
import { DispatchersPage } from './pages/dispatchers/DispatchersPage';
import { DispatcherDetailsPage } from './pages/dispatchers/DispatcherDetailsPage';
import { CompaniesPage } from './pages/companies/CompaniesPage';
import { AddCompanyPage } from './pages/companies/AddCompanyPage';
import { CompanyDetailsPage } from './pages/companies/CompanyDetailsPage';
import { DepartmentDetailsPage } from './pages/departments/DepartmentDetailsPage';

export const App = observer(() => {
    const isRegistrationEnabled = featureTogglesService.isRegistrationEnabled();

    return (
        <Routes>
            <Route path={ROUTES.auth.notFound} element={<NotFoundPage />} />
            <Route path={ROUTES.auth.login} element={<LoginPage />} />
            <Route path={ROUTES.auth.setPassword} element={<SetPasswordPage />} />
            {isRegistrationEnabled && <Route path={ROUTES.auth.register} element={<RegisterPage />} />}
            <Route path={ROUTES.auth.passwordRecovery} element={<PasswordRecoveryPage />} />
            <Route path={ROUTES.drivers.list} element={<GuardRoute><DriversPage /></GuardRoute>}/>
            <Route path={ROUTES.drivers.idPath} element={<GuardRoute><DriverDetailsPage /></GuardRoute>}/>
            <Route path={ROUTES.medicalCheckups.listOfNew} element={<GuardRoute><NewMedicalCheckupsPage /></GuardRoute>}/>
            <Route path={ROUTES.medicalCheckups.history} element={<GuardRoute><MedicalCheckupsHistoryPage /></GuardRoute>}/>
            <Route path={ROUTES.medicalCheckups.journal} element={<GuardRoute><MedicalCheckupsJournalPage /></GuardRoute>}/>
            <Route path={ROUTES.medicalCheckups.idPath} element={<GuardRoute><MedicalCheckupDetailsPage /></GuardRoute>}/>
            <Route path={ROUTES.terminals.list} element={<GuardRoute><TerminalsPage /></GuardRoute>}/>
            <Route path={ROUTES.terminals.idPath} element={<GuardRoute><TerminalDetailsPage /></GuardRoute>}/>
            <Route path={ROUTES.dispatchers.list} element={<GuardRoute><DispatchersPage /></GuardRoute>}/>
            <Route path={ROUTES.dispatchers.idPath} element={<GuardRoute><DispatcherDetailsPage /></GuardRoute>}/>
            <Route path={ROUTES.clients.list} element={<GuardRoute><CompaniesPage /></GuardRoute>}/>
            <Route path={ROUTES.clients.add} element={<GuardRoute><AddCompanyPage /></GuardRoute>}/>
            <Route path={ROUTES.clients.idPath} element={<GuardRoute><CompanyDetailsPage /></GuardRoute>}/>
            <Route path={ROUTES.departments.idPath} element={<GuardRoute><DepartmentDetailsPage /></GuardRoute>}/>
            <Route path="*" element={<Navigate to={ROUTES.auth.login} replace />} />
        </Routes>
    )
})