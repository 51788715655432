import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    FormGroup,
    Stack,
    IconButton,
    Backdrop,
    Chip,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Divider
} from '@mui/material';
import TmFullButton from '../../common/buttons/TmFullButton';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { DepartmentShortDto } from '../../../models/departments/departmentShortDto';
import { CompanyShortDto } from '../../../models/companies/companyShortDto';
import { companiesService } from '../../../services/companiesService';
import { dispatchersService } from '../../../services/dispatchersService';
import ErrorAlert from '../../common/ErrorAlert';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import TmCross from '../../../icons/TmCross';
import Spinner from '../../common/Spinner';
import { DispatcherDetailsDto } from '../../../models/dispatchers/dispatcherDetailsDto';
import {
    EditDispatcherFormDto,
    mapToEditDispatcherFormDto,
    emptyEditDispatcherFormDto,
    mapToAddDispatcherDto,
    mapToEditDispatcherDto
} from '../../../models/dispatchers/editDispatcherFormDto';
import { FileInputFormControl } from '../../common/form/FileInputFormControl';
import ImagePreview from '../../common/ImagePreview';
import { Gender } from '../../../models/enums/gender';
import { NotificationTypeDto } from '../../../models/notificationTypes/notificationTypeDto';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { Dispatcher } from '../../companies/add/models/dispatcher';
import { UserUtils } from '../../../helpers/userUtils';
import { departmentsService } from '../../../services/departmentsService';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';

interface EditDispatcherModalProps {
    open: boolean;
    onClose: () => void;
    onRefresh?: () => void;
    onAddingSuccess?: (newDispatcher: Dispatcher) => void;
    isAdding: boolean;
    dispatcher?: DispatcherDetailsDto;
    companyId?: number;
}

export const EditDispatcherModal: React.FC<EditDispatcherModalProps> = ({
    open,
    onClose,
    onRefresh,
    onAddingSuccess,
    isAdding,
    dispatcher,
    companyId
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
    const [notificationTypes, setNotificationTypes] = useState<NotificationTypeDto[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const methods = useForm<EditDispatcherFormDto>({
        defaultValues: dispatcher ? mapToEditDispatcherFormDto(dispatcher) : emptyEditDispatcherFormDto(companyId),
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            const defaultValues = isAdding ? emptyEditDispatcherFormDto(companyId) : mapToEditDispatcherFormDto(dispatcher!);
            methods.reset(defaultValues);
        }
    }, [open, isAdding, dispatcher, methods, companyId]);

    useEffect(() => {
        companiesService
            .search({
                name: companyId ? undefined : '',
                companyIds: companyId ? [companyId] : undefined,
                page: 0,
                pageSize: 999,
            })
            .then(r => setCompanies(r.data.data.items))
            .catch(e => console.log(e));

        dispatchersService
            .getNotificationTypes()
            .then(r => setNotificationTypes(r.data.data))
            .catch(e => console.log(e));
    }, [companyId]);

    const watchedCompanyIds = methods.watch("companyIds");
    useEffect(() => {
        if (watchedCompanyIds && watchedCompanyIds.length > 0) {
            departmentsService.getByCompanyIds(watchedCompanyIds)
                .then(r => {
                    setDepartments(r.data.data);

                    const currentDepartmentIds = methods.getValues('departmentIds') || [];
                    const validDepartmentIds = r.data.data
                        .filter(dept => currentDepartmentIds?.includes(dept.id)) // Use optional chaining
                        .map(dept => dept.id);

                    methods.setValue('departmentIds', validDepartmentIds);
                })
                .catch(e => {
                    console.error('Error fetching departments:', e);
                    setDepartments([]);
                    methods.setValue('departmentIds', []);
                });
        } else {
            setDepartments([]);
            methods.setValue('departmentIds', []);
        }
    }, [watchedCompanyIds, methods]);

    const onSubmit = (formData: EditDispatcherFormDto) => {
        setLoading(true);

        const serviceCall = isAdding
            ? dispatchersService.add(mapToAddDispatcherDto(formData))
            : dispatchersService.edit(mapToEditDispatcherDto(formData));

        serviceCall
            .then(r => {
                onClose();
                if (onRefresh) {
                    onRefresh();
                }
                if (isAdding && onAddingSuccess) {
                    const added = {
                        id: r.data.data,
                        fullName: UserUtils.getFio(formData),
                        phone: formData.phoneNumber,
                    };
                    onAddingSuccess(added);
                }
                if (!companyId) {
                    navigate(ROUTES.dispatchers.list);
                }
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const onSubmitError = (errors: any) => {
        showAlert("Исправьте ошибки ввода");
        console.log('Form Errors:', errors);
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    };

    function handleDismiss(): void {
        setLoading(true);
        setOpenConfirmDialog(false);
        const dispatcherId = methods.getValues("id");

        if (dispatcherId) {
            dispatchersService.dismiss(dispatcherId)
                .then(() => {
                    methods.setValue("isDismissed", true);
                    if (onRefresh) {
                        onRefresh();
                    }
                })
                .catch(e => {
                    console.log(e);
                    setError(e.message);
                })
                .finally(() => setLoading(false));
        }
    }

    const photoFile = methods.watch("photoFile");
    const photoUrl = methods.watch("photoUrl");

    return (
        <Box>
            <FormProvider {...methods}>
                <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
                    {error && (
                        <ErrorAlert
                            message={error}
                            onClose={() => setError('')}
                        />
                    )}
                    <DialogTitle variant='h5' sx={{ textAlign: 'center' }}>
                        {isAdding ? "Добавление нового диспетчера" : "Редактирование диспетчера"}
                    </DialogTitle>
                    {!error && (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                                zIndex: 10000
                            }}
                        >
                            <TmCross fill="none" stroke="#cccdd5" />
                        </IconButton>
                    )}
                    <DialogContent>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6' mb={1}>Личные данные</Typography>
                                        <Stack spacing={2} direction="column">
                                            <TextFieldFormControl
                                                name="lastName"
                                                label="Фамилия"
                                                required
                                                maxLength={50}
                                            />
                                            <Box display="flex">
                                                <TextFieldFormControl
                                                    name="firstName"
                                                    label="Имя"
                                                    required
                                                    maxLength={50}
                                                    style={{ marginRight: 10 }}
                                                />
                                                <TextFieldFormControl
                                                    name="middleName"
                                                    label="Отчество"
                                                    maxLength={50}
                                                />
                                            </Box>

                                            <Controller
                                                name="gender"
                                                control={methods.control}
                                                rules={{ required: 'Поле обязательно для заполнения' }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        required
                                                        select
                                                        label="Пол"
                                                        defaultValue=''
                                                        error={Boolean(methods.formState.errors.gender)}
                                                        helperText={methods.formState.errors.gender?.message}
                                                    >
                                                        <MenuItem value='' disabled>Выберите пол</MenuItem>
                                                        <MenuItem key={Gender.Male} value={Gender.Male}>Мужской</MenuItem>
                                                        <MenuItem key={Gender.Female} value={Gender.Female}>Женский</MenuItem>
                                                    </TextField>
                                                )}
                                            />
                                            <TextFieldFormControl
                                                name="phoneNumber"
                                                label="Телефон"
                                                type='tel'
                                                required
                                                maxLength={20}
                                            />
                                            <TextFieldFormControl
                                                name="email"
                                                label="Email"
                                                type='email'
                                                required
                                                maxLength={128}
                                                validationRules={{
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                        message: 'Невалидный email',
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6' mb={1}>Клиенты и объекты</Typography>
                                        <Stack spacing={2} direction="column">
                                            <Controller
                                                name='companyIds'
                                                control={methods.control}
                                                defaultValue={[]}
                                                rules={{ required: 'Поле обязательно для заполнения' }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="companyIds"
                                                        options={companyId ? [] : companies} // Render no options if companyId is provided
                                                        getOptionLabel={(item) => (item?.name ?? "")}
                                                        value={companies.filter(company => value?.includes(company.id) ?? false)}
                                                        onChange={(event, newValue) => {
                                                            if (!companyId) { // Only allow changes if companyId is not provided
                                                                const newCompanyIds = newValue.map(company => company.id);
                                                                onChange(newCompanyIds);
                                                            }
                                                        }}
                                                        noOptionsText={companyId ? "" : "Не найдено"}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Клиенты'
                                                                error={Boolean(methods.formState.errors.companyIds)}
                                                                helperText={methods.formState.errors.companyIds?.message}
                                                                placeholder="Введите от 3х букв"
                                                                required
                                                                disabled={!!companyId} // Disable if companyId is provided
                                                            />
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                    key={option.id}
                                                                />
                                                            ))
                                                        }
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name='departmentIds'
                                                control={methods.control}
                                                defaultValue={[]}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="departmentIds"
                                                        options={departments}
                                                        getOptionLabel={(item) => (item?.name ?? "")}
                                                        value={departments.filter(department => value?.includes(department.id) ?? false)}
                                                        onChange={(event, newValue) => {
                                                            onChange(newValue.map(department => department.id));
                                                        }}
                                                        noOptionsText="Не найдено"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='Объекты'
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                                <span style={{ color: 'grey', marginLeft: '4px' }}>
                                                                    • {option.company.name}
                                                                </span>
                                                            </li>
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    label={
                                                                        <Typography component="span" sx={{ fontSize: 'inherit' }}>
                                                                            {option.name}
                                                                            <Typography
                                                                                component="span"
                                                                                color="text.secondary"
                                                                                sx={{ ml: 0.5, fontSize: 'inherit' }}
                                                                            >
                                                                                • {option.company.name}
                                                                            </Typography>
                                                                        </Typography>
                                                                    }
                                                                    {...getTagProps({ index })}
                                                                    key={option.id}
                                                                />
                                                            ))
                                                        }
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </FormGroup>

                                    <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
                                    {!isAdding &&
                                        <FormGroup>
                                            <Typography variant='h6' fontWeight="bold" mb="10px">Статус</Typography>
                                            <Stack spacing={2} direction="column">
                                                <Box display="flex">
                                                    <Box style={{ width: '50%' }} pt={1}>
                                                        {methods.getValues("isDismissed")
                                                            ? <DenyLabel>Уволен</DenyLabel>
                                                            : <AcceptLabel>Работает</AcceptLabel>}
                                                    </Box>
                                                    <Box style={{ width: '50%', textAlign: 'end' }}>
                                                        <ShowForPermission catalog={CategoryType.Dispatchers} permission={PermissionType.Delete}>
                                                            <TmFullButton
                                                                onClick={() => setOpenConfirmDialog(true)}
                                                                disabled={!(methods.getValues("id") && !methods.getValues("isDismissed"))}
                                                            >
                                                                Уволить
                                                            </TmFullButton>
                                                        </ShowForPermission>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </FormGroup>}

                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6' mb={1}>Уведомления</Typography>
                                        <Stack spacing={2} direction="column">
                                            <Controller
                                                name='notificationTypeIds'
                                                control={methods.control}
                                                defaultValue={[]}
                                                render={({ field: { onChange, value } }) => (
                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="notificationTypeIds"
                                                        options={notificationTypes}
                                                        getOptionLabel={(item) => (item?.name ?? "")}
                                                        value={notificationTypes.filter(notificationType => value?.includes(notificationType.id) ?? false)}
                                                        onChange={(event, newValue) => {
                                                            let selectedIds = newValue.map(x => x.id);

                                                            const selectedAnyReasonTrue = newValue.some(x => x.anyReason === true);

                                                            if (selectedAnyReasonTrue) {
                                                                selectedIds = newValue.filter(x => x.anyReason === true).map(x => x.id);
                                                            } else {
                                                                selectedIds = newValue.filter(x => x.anyReason === false).map(x => x.id);
                                                            }

                                                            onChange(selectedIds);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label='События для уведомления'
                                                            />
                                                        )}
                                                        renderOption={(props, option) => (
                                                            <li {...props} key={option.id}>
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    label={option.name}
                                                                    {...getTagProps({ index })}
                                                                    key={option.id}
                                                                />
                                                            ))
                                                        }
                                                    />
                                                )}
                                            />

                                            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Почта" />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <Typography variant='h6' sx={{ mt: 2 }}>Фото диспетчера</Typography>
                                        <Stack spacing={2} direction="column">
                                            <FileInputFormControl name="photoFile" />
                                            {(photoFile || photoUrl) && (
                                                <ImagePreview
                                                    file={photoFile}
                                                    url={photoUrl}
                                                    altText="Фото диспетчера"
                                                    onRemove={() => {
                                                        methods.setValue("photoFile", undefined);
                                                        methods.setValue("photoUrl", undefined);
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', paddingBottom: "2em" }}>
                        <TmFullButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width='45%'>
                            Сохранить
                        </TmFullButton>
                    </DialogActions>
                </Dialog>
            </FormProvider>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
            <ConfirmDialog
                open={openConfirmDialog}
                title="Увольнение диспетчера"
                content={
                    <>
                        Вы уверены, что хотите уволить диспетчера
                        <Typography variant="body1" align="center" fontWeight="bold">
                            {`${methods.getValues("lastName")} ${methods.getValues("firstName")} ${methods.getValues("middleName")}`}
                        </Typography>
                    </>
                }
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleDismiss}
            />
        </Box>
    );
};