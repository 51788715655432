import React, { useEffect, useState } from 'react';
import { Box, Typography, MenuItem, TextField, Stack, FormGroup, Autocomplete, Divider, Backdrop } from '@mui/material';
import { Gender } from '../../../models/enums/gender';
import { companiesService } from '../../../services/companiesService';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { driversService } from '../../../services/driversService';
import { UserUtils } from '../../../helpers/userUtils';
import { DepartmentShortDto } from '../../../models/departments/departmentShortDto';
import { Controller, useFormContext } from 'react-hook-form';
import { CompanyShortDto } from '../../../models/companies/companyShortDto';
import { FileInputFormControl } from '../../common/form/FileInputFormControl';
import { DatePickerFormControl } from '../../common/form/DatePickerFormControl';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import { EditDriverFormDto } from '../../../models/drivers/editDriverFormDto';
import ImagePreview from '../../common/ImagePreview';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import TmFullButton from '../../common/buttons/TmFullButton';
import { departmentsService } from '../../../services/departmentsService';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import Spinner from '../../common/Spinner';

interface EditPersonalDataTabProps {
    setError: (message: string) => void;
    onRefresh?: () => void;
    isAdding?: boolean;
    companyId?: number;
}

export const EditPersonalDataTab: React.FC<EditPersonalDataTabProps> = ({ setError, isAdding, onRefresh, companyId }) => {
    const methods = useFormContext<EditDriverFormDto>();
    const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        companiesService.search({
            name: companyId ? undefined : '',
            companyIds: companyId ? [companyId] : undefined,
            page: 0,
            pageSize: 999,
        })
            .then(r => setCompanies(r.data.data.items))
            .catch(e => console.log(e));
    }, []);

    const watchedCompanyId = methods.watch("companyId");
    useEffect(() => {
        if (watchedCompanyId) {
            departmentsService.get(watchedCompanyId)
                .then(r => {
                    setDepartments(r.data.data);

                    // Keep only previously selected departments that are still valid
                    const currentDepartmentIds = methods.getValues('departmentIds');
                    const validDepartmentIds = r.data.data
                        .filter(dept => currentDepartmentIds!.includes(dept.id))
                        .map(dept => dept.id);

                    methods.setValue('departmentIds', validDepartmentIds);
                })
                .catch(e => console.log(e));
        } else {
            // If no company is selected, clear the departments list
            setDepartments([]);
            methods.setValue('departmentIds', []);
        }
    }, [watchedCompanyId, methods.setValue]);

    const handleDismiss = (): void => {
        setLoading(true);
        setOpenConfirmDialog(false);
        const driverId = methods.getValues("id");

        if (driverId) {
            driversService.dismiss(driverId)
                .then(() => {
                    methods.setValue("user.isDismissed", true);
                    if (onRefresh) {
                        onRefresh();
                    }
                })
                .catch(e => {
                    console.log(e);
                    setError(e.message);
                })
                .finally(() => setLoading(false));
        }
    }

    const photoFile = methods.watch("user.photoFile");
    const photoUrl = methods.watch("user.photoUrl");

    return (
        <Box>
            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb="10px">Клиенты и объекты</Typography>
                <Stack spacing={2} direction="column">
                    <Controller
                        name='companyId'
                        control={methods.control}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                size="small"
                                id="companyId"
                                options={companyId ? [] : companies}
                                getOptionLabel={(item) => (item?.name ?? "")}
                                value={companyId
                                    ? companies.find(company => company.id === companyId) || null
                                    : companies.find(company => company.id === value) || null} // Default to null if not found
                                onChange={(event, newValue) => {
                                    if (!companyId) { // Only allow changes if companyId is not provided
                                        onChange(newValue ? newValue.id : null);
                                    }
                                }}
                                noOptionsText={companyId ? "" : "Не найдено"}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Клиент'
                                        error={Boolean(methods.formState.errors.companyId)}
                                        helperText={methods.formState.errors.companyId?.message}
                                        placeholder="Введите от 3х букв"
                                        required
                                        disabled={!!companyId} // Disable if companyId is provided
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                            />
                        )}
                    />
                    <Controller
                        name='departmentIds'
                        control={methods.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                select
                                label="Объекты"
                                size="small"
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (value) => {
                                        const selected = value as number[];
                                        if (selected.length > 2) {
                                            return `${selected.length} выбрано...`;
                                        }
                                        return selected.map((id) => departments.find(d => d.id === id)?.name).join(', ');
                                    },
                                }}
                                error={Boolean(methods.formState.errors.departments)}
                                helperText={methods.formState.errors.departments?.message}
                            >
                                {departments.map((d) => (
                                    <MenuItem key={d.id} value={d.id}>
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Stack>
            </FormGroup>

            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            {!isAdding &&
                <FormGroup>
                    <Typography variant='h6' fontWeight="bold" mb="10px">Статус</Typography>
                    <Stack spacing={2} direction="column">
                        <Box display="flex">
                            <Box style={{ width: '50%' }} pt={1}>
                                {methods.getValues("user.isDismissed")
                                    ? <DenyLabel>Уволен</DenyLabel>
                                    : <AcceptLabel>Работает</AcceptLabel>}
                            </Box>
                            <Box style={{ width: '50%', textAlign: 'end' }}>
                                <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Delete}>
                                    <TmFullButton
                                        onClick={() => setOpenConfirmDialog(true)}
                                        disabled={!(methods.getValues("id") && !methods.getValues("user.isDismissed"))}
                                    >
                                        Уволить
                                    </TmFullButton>
                                </ShowForPermission>
                            </Box>
                        </Box>
                    </Stack>
                </FormGroup>}

            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb="10px">Личные данные</Typography>
                <Stack spacing={2} direction="column">
                    <TextFieldFormControl
                        name="user.lastName"
                        label="Фамилия"
                        required
                    />
                    <Box display="flex">
                        <TextFieldFormControl
                            name="user.firstName"
                            label="Имя"
                            required
                            style={{ marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="user.middleName"
                            label="Отчество"
                            required
                        />
                    </Box>
                    <Box display="flex">
                        <Controller
                            name="user.gender"
                            control={methods.control}
                            rules={{ required: 'Поле обязательно для заполнения' }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    required
                                    select
                                    label="Пол"
                                    defaultValue=''
                                    error={Boolean(methods.formState.errors.user?.gender)}
                                    helperText={methods.formState.errors.user?.gender?.message}
                                    style={{ width: '50%', marginRight: 10 }}
                                >
                                    <MenuItem value='' disabled>Выберите пол</MenuItem>
                                    <MenuItem key={Gender.Male} value={Gender.Male}>Мужской</MenuItem>
                                    <MenuItem key={Gender.Female} value={Gender.Female}>Женский</MenuItem>
                                </TextField>
                            )}
                        />

                        <DatePickerFormControl
                            name="user.birthDate"
                            label={"Дата рождения"}
                            style={{ width: '50%' }}
                            required
                        />
                    </Box>
                </Stack>
            </FormGroup>
            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            <FormGroup>
                <Typography variant='h6' fontWeight="bold" mb={0.5}>Документы</Typography>
                <Stack spacing={0} direction="column">
                    <Typography fontWeight="bold" mb={2}>Водительское удостоверение</Typography>
                    <Box display="flex" sx={{ mb: 2 }}>
                        <TextFieldFormControl
                            name="licenceSerial"
                            label="Серия"
                            style={{ width: '33%', marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="licenceNumber"
                            label="Номер"
                            style={{ width: '33%', marginRight: 10 }}
                        />

                        <DatePickerFormControl
                            name="licenceIssued"
                            label={"Дата выдачи"}
                            style={{ width: '33%' }}
                        />

                    </Box>
                    <Typography fontWeight="bold" mb={2}>Снилс</Typography>
                    <TextFieldFormControl
                        name="snils"
                        label="№ Снилс"
                        style={{ marginBottom: 10 }}
                    />
                    <Typography fontWeight="bold" mb={2}>Паспорт</Typography>
                    <Box display="flex">
                        <TextFieldFormControl
                            name="passportSerial"
                            label="Серия"
                            style={{ marginRight: 10 }}
                        />
                        <TextFieldFormControl
                            name="passportNumber"
                            label="Номер"
                        />
                    </Box>
                </Stack>
            </FormGroup>
            <Divider sx={{ my: 1, borderBottomWidth: 0 }} />
            <Typography variant='h6' fontWeight="bold" mb={2}>Служебная информация</Typography>
            <Stack spacing={2} direction="column">
                <TextFieldFormControl
                    name="employeeNumber"
                    label="Табельный номер"
                    required
                    style={{ marginBottom: 10 }}
                />
            </Stack>

            <Typography variant='h6' fontWeight="bold" mb={0.5}>Фото водителя</Typography>
            <Stack spacing={2} direction="column">
                <FileInputFormControl name="user.photoFile" />
                {(photoFile || photoUrl) && (
                    <ImagePreview
                        file={photoFile}
                        url={photoUrl}
                        altText="Driver Photo"
                        onRemove={() => {
                            methods.setValue("user.photoFile", undefined);
                            methods.setValue("user.photoUrl", undefined);
                        }}
                    />
                )}
            </Stack>
            <ConfirmDialog
                open={openConfirmDialog}
                title="Увольнение водителя"
                content={
                    <>
                        Вы уверены, что хотите уволить водителя
                        <Typography variant="body1" align="center" fontWeight="bold">
                            {UserUtils.getFio(methods.getValues("user"))}
                        </Typography>
                    </>
                }
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleDismiss}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
        </Box>
    );
};